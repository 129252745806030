* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #131723;
    height: 100%;
}

a {
    text-decoration: none;
}

#root {
    height: 100%;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    background: #313542;
}

* ::-webkit-scrollbar-thumb {
    background: #3F3F44;
    border-radius: 99999px;
}

*::-webkit-scrollbar-thumb:hover {
    background: #BBBBBB;
}
